import { template as template_662f9f3f7e144533a39f571c04141792 } from "@ember/template-compiler";
const FKText = template_662f9f3f7e144533a39f571c04141792(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
